import React from 'react'

import Layout from '../components/layout'

import { DataCard } from '../components/card'

import { graphql } from 'gatsby'

export default ({ data }) => {
    const { node: page } = data.allWordpressPage.edges[0]
    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Privacy Policy'
                }
            ]}
            marketTicker
        >
            <div className="pageContainer">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <DataCard label={'Privacy Policy'} h1>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: page.content
                                    }}
                                />
                            </DataCard>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "privacy-policy" } }) {
            edges {
                node {
                    slug
                    content
                }
            }
        }
    }
`
